import React from 'react';

import nameImage1 from 'assets/images/pages/overons_naamenlogo/1.jpg';
import nameImage2 from 'assets/images/pages/overons_naamenlogo/2.jpg';
import nameImage3 from 'assets/images/pages/overons_naamenlogo/3.jpg';
import nameImage4 from 'assets/images/pages/overons_naamenlogo/4.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

const OverOnsNaamLogo = () => (
  <Layout>
    <Seo image={{ src: nameImage1 }} />

    <Title
      image={{ src: nameImage1 }}
      alt="Over onze naam en ons logo"
      subtitle="Hoe de naam Batao en het logo zijn geboren"
      title="Over onze naam en ons logo"
    >
      We zien onszelf graag als een kleine stam van moderne krijgers, klaar om
      onze klanten te helpen de online competitie te verslaan of nieuwe
      technische uitdagingen te overwinnen.
    </Title>

    <Section>
      <Image image={{ src: nameImage2 }} alt="Geografische betekenis">
        <Heading size="xl" as="h2">
          Geografische betekenis
        </Heading>

        <Text>
          “Batao is een gelatiniseerde vorm van een Nederlands (Germaanse)
          stamnaam, waarschijnlijk dichtbij Batavi. Zoals vele Nederlandse
          stamnamen, is de betekenis terug te vinden in de geografische ligging
          en afgeleid van twee elementen: bat, wat goed of uitstekend betekent,
          en avo, wat land betekent. De stamnaam betekent kort gezegd ‘de mensen
          van het uitstekende land.’”
        </Text>
      </Image>
    </Section>

    <Section>
      <Image
        image={{ src: nameImage3 }}
        alt="Met liefde gebouwd in Nijmegen"
        position="right"
      >
        <Heading size="xl" as="h2">
          Met liefde gebouwd in Nijmegen
        </Heading>

        <Text spacing="lg">
          Wij zijn een Nederlands bedrijf dat werkt met Magento Certified en
          gekwalificeerde mensen die opereren vanuit ons eigen kantoor in
          Nijmegen. We streven naar volledige klanttevredenheid omdat wij vinden
          dat niets minder dan dat acceptabel is voor de trots en eer van onze
          stam. Niet outsourcen naar het oostblok of India, maar zelf
          verantwoordelijkheid nemen voor de kwaliteit.
        </Text>

        <Button color="tertiary" to="/contact">
          Kom langs bij ons in Nijmegen
        </Button>
      </Image>
    </Section>

    <Section>
      <Image image={{ src: nameImage4 }} alt="Uitstekende Troepen">
        <Heading size="xl" as="h2">
          Uitstekende Troepen
        </Heading>

        <Text spacing="sm">
          “De Batavieren, sinds in elk geval 12 vC, waren een van de eerste en
          meest trouwe bondgenoten van Rome, en leverden zowel grondtroepen als
          bereden strijders. De Romeinen hielden hun bekwaamheid in rijkunst en
          zwemvaardigheid in hoog aanzien, omdat de Bataafse mannen en paarden
          de Rijn konden oversteken zonder uit formatie te raken, aldus
          beschreven door de historicus Tacitus. Deze vaardigheid was belangrijk
          omdat hun grondgebied tussen twee grote rivieren lag, doorsneden door
          vele kleinere stroompjes en onderhevig aan regelmatige
          overstromingen.”
        </Text>

        <Text>
          Onze stam van e-commerce experts, uit dezelfde regio als de originele
          Batavieren, is klaar om vriend en vijand te verrassen met dezelfde
          vaardigheid om snel en gedisciplineerd het werk gedaan te krijgen.
          Vandaag staan wij klaar om u te helpen rivieren over te steken en
          nieuw land in te nemen!
        </Text>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default OverOnsNaamLogo;
